
.main-wrapper {
    height: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;

    .text-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .table-content {
        height: 100%;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .customTable {
            width: 100%;
            flex: 1;
            margin-top: 20px;

            .show-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .link-box {
                margin-left: 20px;
            }
            ::v-deep tbody {
                tr.el-table__row {
                    td:first-child .cell {
                        display: none;
                    }
                }
                tr.el-table__row:first-child {
                    td:first-child .cell {
                        display: block;
                    }
                }
            }
        }

        .top-tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #F1F5FF;

            .go-back {
                margin-bottom: 12px;
            }
        }
    }
}
.goods-info {
    display: flex;
    align-items: center;
    .good-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        cursor: pointer;
    }
}
.goods-img-dialog {
    .upload-file-content {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 445px;
        .big-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 444px;
            height: 444px;
            img {
                max-width: 50%;
                max-height: 50%;
            }
        }
    }
}
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: #000000;
    opacity: 0.6;
    }
    .videomasks {
        width: 800px;
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 20;
        transform: translate(-50%, -50%);
    }
    .videomasks img {
    width: 100%;
    height: 600px;
    background: #000;
    }
    .comment-top-btn {
        position: fixed;
        right: 0;
        top: 0;
        width: 16px;
        height: 16px;
        margin: 22px 20px 0 0;
        background: url('../../../../../assets/close_btn.png');
        cursor: pointer;
        // border: 1px solid red;
    }
